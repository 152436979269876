import React from 'react';

import ConsultantLayout from '../../layout/ConsultantLayout';
import ResumeView from '../../views/consultant/ResumeView';

const ConsultantResumePage = (): React.ReactElement => {
  return (
    <>
      <ConsultantLayout>
        <ResumeView />
      </ConsultantLayout>
    </>
  );
};

export default ConsultantResumePage;
